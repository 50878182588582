import React from "react";
import ChangeTheme from "../themes/ChangeTheme";
import logo from "../../assets/images/IMG_4242.PNG";
import { FaShuffle, FaTrophy } from "react-icons/fa6";
import { IoExit } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const TarbaaHeader = ({ theme, game, setGame, finishButton = true }) => {
  const navigate = useNavigate();
  const nextTurn = () => {
    const currentTurnIndex = game.teams.findIndex((team) => team.currentTurn);
    const nextTurnIndex =
      currentTurnIndex === game.teams.length - 1 ? 0 : currentTurnIndex + 1;

    const updatedTeams = game.teams.map((team, index) => ({
      ...team,
      currentTurn: index === nextTurnIndex,
    }));

    setGame({ ...game, teams: updatedTeams });
  };

  return (
    <div className="w-full ">
      <div className="w-full flex flex-row md:flex-row items-center justify-between p-2 md:pl-4 gap-1 h-[11vh]  bg-background rounded-b-2xl lg:rounded-b-3xl">
        {/* Left Buttons */}
        <div className="flex flex-row items-center gap-2">
          <button
            className="flex items-center gap-2 text-white px-2 py-1 lg:px-3 lg:py-2 rounded-xl border-white border-2 font-bold uppercase text-sm md:text-base shadow hover:shadow-lg transition duration-150"
            type="button"
            onClick={() => navigate("/")}
          >
            <IoExit className="w-5 h-5 md:w-6 md:h-6 transform rotate-180" />
            <h1 className="hidden md:block">الخروج</h1>
          </button>
          {/* End Game Button */}
          {finishButton && (
            <button
              onClick={() => navigate(`/${game?.codeInfo?.code || "old"}/end`)}
              className="flex items-center gap-2 text-white px-2 py-1 lg:px-3 lg:py-2 rounded-xl border-white border-2 font-bold uppercase text-sm md:text-base shadow hover:shadow-lg transition duration-150"
              type="button"
            >
              <FaTrophy className="w-5 h-5 md:w-6 md:h-6" />
              <h1 className="hidden lg:block">إنهاء اللعبة</h1>
            </button>
          )}
          <ChangeTheme theme={theme} />
        </div>

        {/* Game Name */}
        <div className="text-white text-lg md:text-xl font-bold text-center ">
          {game?.name}
        </div>

        {/* Right Side */}
        <div className="flex flex-row items-center gap-2 ">
          {/* Skip Turn Button */}
          {finishButton && (
            <button
              className="flex items-center gap-2 text-white px-2 py-1 lg:px-3 lg:py-2 rounded-full border-white border-2 text-sm md:text-base"
              onClick={nextTurn}
            >
              <FaShuffle className="w-3 h-3 md:w-4 md:h-4" />
              <h1>
                دور فريق: {game?.teams?.find((team) => team.currentTurn)?.name}
              </h1>
            </button>
          )}
          {/* Logo */}
          <img
            className="h-10 w-10  lg:h-16 lg:w-16 object-contain text-white"
            src={logo}
            alt="تربع"
          />
        </div>
      </div>
    </div>
  );
};

export default TarbaaHeader;
