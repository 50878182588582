import React from "react";
import { IoIosColorPalette } from "react-icons/io";
import TarbaaImage from "../../assets/themes/Tarbaa.png";
import SJImage from "../../assets/themes/SJ.png";
import MKHImage from "../../assets/themes/MKH.png";
import { FaXmark } from "react-icons/fa6";

const ChangeTheme = ({ theme }) => {
  const [showModal, setShowModal] = React.useState(false);
  const changeTheme = (theme) => {
    localStorage.setItem("theme", theme);
    window.location.reload();
  };
  return (
    <>
      <button
        className="flex items-center gap-2 text-white px-2 py-1 lg:px-3 lg:py-2 rounded-xl border-white border-2 font-bold uppercase text-sm md:text-base shadow hover:shadow-lg transition duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <IoIosColorPalette className="w-5 h-5 md:w-6 md:h-6" />
      </button>
      {showModal ? (
        <>
          <div className="z-[9999999] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-md md:max-w-3xl ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="  flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <div>
                    <button
                      className=" p-1 ml-auto bg-transparent border-0 text-black opacity-15 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <FaXmark />
                    </button>
                  </div>
                  <div>
                    <h3 className="text-3xl font-semibold text-right">
                      إختيار تصميم اللعبة
                    </h3>
                  </div>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-row flex-wrap gap-4 items-center justify-center">
                    <button
                      style={{
                        borderColor:
                          theme !== "SJ" && theme !== "MKH"
                            ? "#FFE392"
                            : "lightgray",
                      }}
                      className="bg-transparent border-2 text-black  text-3xl leading-none  outline-none focus:outline-none w-5/12  border-black rounded-lg overflow-hidden aspect-video"
                      onClick={() => {
                        changeTheme("Tarbaa");
                      }}
                    >
                      <img
                        className="w-full h-full object-cover"
                        src={TarbaaImage}
                        alt="Tarbaa"
                      />
                    </button>
                    <button
                      disabled
                      style={{
                        borderColor: theme === "SJ" ? "#FFE392" : "lightgray",
                      }}
                      className="disabled:opacity-40  bg-transparent border-2 text-black  text-3xl leading-none  outline-none focus:outline-none w-5/12  border-black rounded-lg overflow-hidden aspect-video"
                      onClick={() => {
                        changeTheme("SJ");
                      }}
                    >
                      <img
                        className="w-full h-full object-cover"
                        src={SJImage}
                        alt="SJ"
                      />
                    </button>
                    <button
                      disabled
                      style={{
                        borderColor: theme === "MKH" ? "#FFE392" : "lightgray",
                      }}
                      className="disabled:opacity-40 bg-transparent border-2 text-black  text-3xl leading-none  outline-none focus:outline-none w-5/12  border-black rounded-lg overflow-hidden aspect-video"
                      onClick={() => {
                        changeTheme("MKH");
                      }}
                    >
                      <img
                        className="w-full h-full object-cover"
                        src={MKHImage}
                        alt="MKH"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ChangeTheme;
