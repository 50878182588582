import React, { useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Tarbaa = ({ game }) => {
  const navigate = useNavigate();
  const [gameState, setGameState] = useState(game);

  useEffect(() => {
    setGameState(game);
  }, [game]);

  return (
    <div className="w-full h-[89vh] ">
      <div className="w-full h-[69vh] lg:h-[76vh] flex flex-row flex-wrap p-1 justify-center">
        {gameState?.modes?.map((mode, index) => (
          <div className="w-1/3 h-1/2  sm:w-1/6 sm:h-full    md:text-xl  lg:text-2xl font-bold">
            <div
              className={`relative w-full h-full p-1  ${
                index !== 0 ? "border-l-[1px]" : ""
              }`}
            >
              <div
                className={`relative h-1/6 flex justify-center items-end rounded-xl text-center ${
                  mode?.custom && "border-4 border-mainText"
                } `}
              >
                <div className="z-20 h-full lg:h-2/4 flex justify-center items-center w-full bg-[#00000090] rounded-lg">
                  <h1 className=" text-white text-center  md:text-lg  lg:text-xl">
                    {mode?.name}
                  </h1>
                </div>
                <div className="absolute w-full h-full z-0  overflow-hidden rounded-lg ">
                  <img
                    src={mode?.image}
                    alt={mode?.name}
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>

              <div className="h-5/6">
                {mode?.questions
                  ?.sort(
                    (a, b) =>
                      (b.question?.points || b.customQuestion?.points) -
                      (a.question?.points || a.customQuestion?.points)
                  )
                  .map((q) => (
                    <div className="h-1/6 pt-1 lg:pt-3 px-1">
                      <button
                        onClick={() => {
                          navigate(`/question/${q?._id}`);
                        }}
                        disabled={
                          q?.question?.answered || q?.customQuestion?.answered
                        }
                        className="h-full w-full bg-[#bcc2d3] text-white rounded-xl text-center disabled:bg-[#d6d9e1] disabled:text-[#8e8e8e] disabled:opacity-40"
                      >
                        {q?.question?.points || q?.customQuestion?.points}
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full h-[21vh] lg:h-[14vh] flex justify-center items-center overflow-hidden bg-brown border-t-[1vh] border-mainButtonShadow">
        <div className="h-full w-full flex flex-row p-2 justify-center">
          {gameState?.teams.map((team, index) => (
            <div className="w-1/4 h-full px-[1px] lg:p-2" key={index}>
              <div
                className={`w-full h-full flex flex-col sm:flex-row justify-center items-center  bg-[#dd9c6e] rounded-lg ${
                  team.currentTurn === true && "border-4"
                }`}
              >
                <div className="w-full h-1/2 sm:w-1/3  sm:h-full flex  justify-center items-center p-1 sm:p-2">
                  <div className=" h-full  aspect-square rounded-full overflow-hidden flex justify-center items-center">
                    <div className=" w-full aspect-square rounded-full overflow-hidden ">
                      <img
                        src={team?.image?.image}
                        alt={team?.name}
                        className="w-full h-full object-cover "
                      />
                    </div>
                  </div>
                </div>
                <div className="h-1/2 w-full sm:w-2/3 sm:h-full text-sm md:text-xl  lg:text-2xl font-bold ">
                  <div className=" h-1/2 flex items-center justify-center overflow-hidden">
                    <h1 className="text-white  text-center">{team?.name}</h1>
                  </div>
                  <div className="w-full h-1/2 flex justify-between items-center p-2">
                    <button
                      onClick={() => {
                        const updatedGameState = { ...gameState }; // Create a shallow copy
                        updatedGameState.teams[index].points -= 50; // Update the points
                        setGameState(updatedGameState); // Update the state to trigger re-render
                        localStorage.setItem(
                          "game",
                          JSON.stringify(updatedGameState)
                        ); // Save to localStorage
                      }}
                      className="  bg-mainButtonShadow hover:bg-mainButton rounded-lg p-2"
                    >
                      <FaMinus className="text-[#dd9c6e] size-3 lg:size-5" />
                    </button>
                    <h1 className="text-white  font-bold">{team?.points}</h1>
                    <button
                      onClick={() => {
                        const updatedGameState = { ...gameState }; // Create a shallow copy
                        updatedGameState.teams[index].points += 50; // Update the points
                        setGameState(updatedGameState); // Update the state to trigger re-render
                        localStorage.setItem(
                          "game",
                          JSON.stringify(updatedGameState)
                        ); // Save to localStorage
                      }}
                      className=" bg-mainButtonShadow hover:bg-mainButton rounded-lg p-2"
                    >
                      <FaPlus className="text-[#dd9c6e] size-3 lg:size-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tarbaa;
