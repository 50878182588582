import Lottie from "lottie-react";
import React, { useEffect } from "react";
import goldBadge from "../assets/animation/gold.json";
import silverBadge from "../assets/animation/silver.json";
import bronzeBadge from "../assets/animation/bronze.json";
import confetti from "../assets/animation/confetti.json";
import TarbaaHeader from "../components/header/TarbaaHeader";

const Winning = () => {
  const game = JSON.parse(localStorage.getItem("game"));

  // Sort teams by points (descending)
  const teamsBasedOnScore =
    game?.teams?.sort((a, b) => b.points - a.points) || [];

  // Extract distinct point values
  const distinctPoints = [...new Set(teamsBasedOnScore.map((t) => t.points))];

  // Create a map from points to rank
  // Rank assignment: first distinct points => rank 1 (gold), second => rank 2 (silver), third => rank 3 (bronze)
  const pointsToRank = {};
  distinctPoints.forEach((pts, idx) => {
    pointsToRank[pts] = idx + 1;
  });

  const getBadgeAnimation = (rank) => {
    if (rank === 1) return goldBadge;
    if (rank === 2) return silverBadge;
    if (rank === 3) return bronzeBadge;
    return null;
  };

  const getRankStyle = (rank) => {
    // Adjust podium height differences based on rank, not index
    // If multiple golds: They all get the top style.
    switch (rank) {
      case 1: // Gold rank
        return "translate-y-[-50px] bg-yellow-300";
      case 2: // Silver rank
        return "translate-y-[-20px] bg-slate-300";
      case 3: // Bronze rank
        return "translate-y-[-20px] bg-orange-300";
      default:
        return "bg-white"; // No special podium background for others
    }
  };

  const getTextSize = (rank) => {
    if (rank === 1) return "text-lg lg:text-3xl font-extrabold";
    if (rank === 2) return " lg:text-2xl font-semibold";
    if (rank === 3) return " text-sm lg:text-xl font-bold";
    return "text-sm lg:text-xl";
  };

  // For podium arrangement (top 3 ranks only)
  // If multiple teams share a rank, they all appear in a row.
  // We'll arrange only top 3 ranks in a row: gold (center), silver (left), bronze (right).
  // If multiple gold winners, they all appear and may push arrangement visually.
  // We can just sort teams by rank and points, then position them accordingly.
  const podiumTeams = teamsBasedOnScore.filter(
    (t) => pointsToRank[t.points] <= 3
  );

  // Sort podiumTeams by rank to place gold in center, silver on left, bronze on right
  // If ties occur, multiple golds all get the 'center' logic.
  // We'll do a simple approach:
  // Gold rank: order 2 (center)
  // Silver rank: order 1 (left)
  // Bronze rank: order 3 (right)
  // If multiple teams have the same rank, they line up horizontally.
  const podiumOrderClass = (rank) => {
    switch (rank) {
      case 1:
        return "order-2";
      case 2:
        return "order-1";
      case 3:
        return "order-3";
      default:
        return "";
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("game");
    };
  }, []);

  return (
    <>
      <TarbaaHeader finishButton={false} game={game} />
      <div className="relative h-[89vh] w-screen flex flex-col justify-start items-center bg-gradient-to-b from-white to-mainText p-10 overflow-hidden">
        {/* Confetti background */}
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
          <Lottie animationData={confetti} loop={true} />
        </div>
        <div className="absolute top-0 left-0 w-1/2 h-1/2 translate-y-full pointer-events-none">
          <Lottie animationData={confetti} loop={true} />
        </div>
        <div className="absolute top-0 right-0 w-1/2 h-1/2 translate-y-full pointer-events-none">
          <Lottie animationData={confetti} loop={true} />
        </div>

        {teamsBasedOnScore.length > 0 && (
          <div className="flex flex-col items-center w-full h-full pt-14">
            {/* Podium container */}
            <div className="flex items-end justify-center w-full gap-8 flex-wrap">
              {podiumTeams.map((team) => {
                const rank = pointsToRank[team.points];
                const badge = getBadgeAnimation(rank);
                return (
                  <div
                    key={team.name}
                    className={`relative overflow-hidden flex flex-col items-center justify-end p-5 w-1/5 rounded-lg gap-5 shadow-xl transition-transform duration-300 hover:scale-105 ${getRankStyle(
                      rank
                    )} ${podiumOrderClass(rank)}`}
                  >
                    {/* Team Image & Badge Container */}
                    <div className="relative flex flex-col items-center ">
                      <img
                        src={team?.image?.image}
                        alt={`${team.name}'s logo`}
                        className="w-24 h-24 object-cover rounded-full border-4 border-white"
                      />
                      {/* Badge overlay at bottom center of image */}
                      {badge && (
                        <div className="absolute -bottom-5">
                          <div className="w-30 h-30 relative">
                            <Lottie animationData={badge} />
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Name & Points */}
                    <div>
                      <h1
                        className={`${getTextSize(
                          rank
                        )} text-gray-800 text-center `}
                      >
                        {team.name}
                      </h1>
                      <h1
                        className={`${getTextSize(
                          rank
                        )} text-gray-800 text-center `}
                      >
                        {team.points}
                      </h1>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Others */}
            {teamsBasedOnScore.length > podiumTeams.length && (
              <div className="flex flex-col items-center w-full h-full pt-14">
                {/* Podium container */}
                <div className="flex items-end justify-center w-full gap-8 flex-wrap">
                  {teamsBasedOnScore.slice(podiumTeams.length).map((team) => (
                    <div
                      key={team.name}
                      className="flex flex-col items-center gap-3 bg-white p-5 rounded-lg shadow-lg w-1/5 overflow-hidden"
                    >
                      <img
                        src={team?.image?.image}
                        alt={`${team.name}'s logo`}
                        className="w-20 h-20 object-cover rounded-full border-2 border-gray-300"
                      />
                      <h1 className="text-sm lg:text-xl font-medium text-gray-800 text-center">
                        {team.name}
                      </h1>
                      <h1 className="text-gray-600 text-center text-sm lg:text-xl">
                        {team.points} pts
                      </h1>
                      {/* Crying emoji to represent not making the podium */}
                      <span className="text-4xl">😢</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Winning;
