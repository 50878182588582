import React from "react";

import ModesSection from "../sj/ModesSection";
import TeamsSection from "../sj/TeamsSection";

const SJ = ({ game, ads }) => {
  console.log(ads);
  return (
    <div className="w-full">
      <ModesSection game={game} />
      {/* Teams Section */}
      <TeamsSection game={game} ads={ads} />
    </div>
  );
};

export default SJ;
