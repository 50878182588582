import React from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { FaArrowDownUpAcrossLine, FaHandPeace } from "react-icons/fa6";

const TeamsSection = ({ game, ads }) => {
  return (
    <div className=" min-h-[21vh] bg-[#d1d3d4] flex flex-wrap justify-between">
      {/* First Team */}
      <div className=" w-1/2 md:w-5/12 flex flex-row flex-wrap justify-center items-start">
        {/* Left Side (TeamCard) */}
        {0 % 2 === 0 && (
          <>
            <TeamCard team={game?.teams[0]} />
            {/* Power-Ups Section */}
            <div className="">
              <h1 className="text-center text-lg md:text-2xl font-sans font-bold">
                وسائل المساعدة
              </h1>
              <div className="flex flex-row gap-2">
                {game?.teams[0]?.powerUps.map((powerUp, index) => (
                  <>
                    {index === 0 ? (
                      <button className="rounded-full border-[4px] border-secondaryButton p-2">
                        <FaArrowDownUpAcrossLine className="text-secondaryButton text-2xl" />
                      </button>
                    ) : index === 1 ? (
                      <button className="rounded-full border-[4px] border-gray-400 p-2">
                        <BsTelephoneFill className="text-gray-400 text-2xl" />
                      </button>
                    ) : (
                      <button className="rounded-full border-[4px] border-gray-400 p-2">
                        <FaHandPeace className="text-gray-400 text-2xl" />
                      </button>
                    )}
                  </>
                ))}
              </div>
            </div>
            {game?.teams[2] && (
              <>
                <TeamCard team={game?.teams[2]} />
                {/* Power-Ups Section */}
                <div className="">
                  <h1 className="text-center text-lg md:text-2xl font-sans font-bold">
                    وسائل المساعدة
                  </h1>
                  <div className="flex flex-row gap-2">
                    {game?.teams[2]?.powerUps.map((powerUp, index) => (
                      <>
                        {index === 0 ? (
                          <button className="rounded-full border-[4px] border-secondaryButton p-2">
                            <FaArrowDownUpAcrossLine className="text-secondaryButton text-2xl" />
                          </button>
                        ) : index === 1 ? (
                          <button className="rounded-full border-[4px] border-gray-400 p-2">
                            <BsTelephoneFill className="text-gray-400 text-2xl" />
                          </button>
                        ) : (
                          <button className="rounded-full border-[4px] border-gray-400 p-2">
                            <FaHandPeace className="text-gray-400 text-2xl" />
                          </button>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {/* Ads Placeholder */}
      {ads && (
        <div className=" w-full md:w-1/12 px-64 p-4 md:p-0 flex flex-row flex-wrap justify-center items-center order-last md:order-none">
          {/* Ads Placeholder Div */}
          <div className="w-full h-full flex justify-center items-center aspect-square">
            <img
              src={ads[0]?.image}
              alt={ads[0]?.name}
              className="w-full h-full aspect-square object-contain"
            />
          </div>
        </div>
      )}

      {/* Second Team */}
      <div className="w-1/2 md:w-5/12 flex flex-row flex-wrap justify-center items-start">
        {/* Right Side (TeamCard) */}
        {1 % 2 !== 0 && (
          <>
            {/* Power-Ups Section */}
            <div className="">
              <h1 className="text-center text-lg md:text-2xl font-sans font-bold">
                وسائل المساعدة
              </h1>
              <div className="flex flex-row gap-2">
                {game?.teams[1]?.powerUps.map((powerUp, index) => (
                  <>
                    {index === 0 ? (
                      <button className="rounded-full border-[4px] border-secondaryButton p-2">
                        <FaArrowDownUpAcrossLine className="text-secondaryButton text-2xl" />
                      </button>
                    ) : index === 1 ? (
                      <button className="rounded-full border-[4px] border-gray-400 p-2">
                        <BsTelephoneFill className="text-gray-400 text-2xl" />
                      </button>
                    ) : (
                      <button className="rounded-full border-[4px] border-gray-400 p-2">
                        <FaHandPeace className="text-gray-400 text-2xl" />
                      </button>
                    )}
                  </>
                ))}
              </div>
            </div>
            <TeamCard team={game?.teams[1]} />
            {/* Power-Ups Section */}
            {game?.teams[3] && (
              <>
                <div className="">
                  <h1 className="text-center text-lg md:text-2xl font-sans font-bold">
                    وسائل المساعدة
                  </h1>
                  <div className="flex flex-row gap-2">
                    {game?.teams[3]?.powerUps.map((powerUp, index) => (
                      <>
                        {index === 0 ? (
                          <button className="rounded-full border-[4px] border-secondaryButton p-2">
                            <FaArrowDownUpAcrossLine className="text-secondaryButton text-2xl" />
                          </button>
                        ) : index === 1 ? (
                          <button className="rounded-full border-[4px] border-gray-400 p-2">
                            <BsTelephoneFill className="text-gray-400 text-2xl" />
                          </button>
                        ) : (
                          <button className="rounded-full border-[4px] border-gray-400 p-2">
                            <FaHandPeace className="text-gray-400 text-2xl" />
                          </button>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <TeamCard team={game?.teams[3]} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const TeamCard = ({ team }) => {
  return (
    <div className="w-full sm:w-1/2 flex flex-col gap-1 p-2">
      {/* Team Name */}
      <div className="bg-secondaryButton rounded-full flex justify-center items-center">
        <h1 className="text-center md:text-lg font-sans font-bold text-white">
          {team?.name}
        </h1>
      </div>

      {/* Points and Controls */}
      <div className="border-[3px] border-gray-500 rounded-full flex flex-row justify-between items-center px-[0.5px] md:px-1">
        <button>
          <FaMinusCircle size={30} className="text-secondaryButton " />
        </button>
        <h1 className="text-center text-xl md:text-3xl font-sans font-bold text-secondaryButton">
          {team?.points}
        </h1>
        <button>
          <FaPlusCircle size={30} className="text-secondaryButton " />
        </button>
      </div>
    </div>
  );
};

export default TeamsSection;
