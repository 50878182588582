import { useQuery } from "@tanstack/react-query";
import { treadmill } from "ldrs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAds } from "../api/ads";
import { getGameByCode } from "../api/game";
import SJHeader from "../components/header/SJHeader";
import MKH from "../components/themes/MKH";
import SJ from "../components/themes/SJ";
import Tarbaa from "../components/themes/Tarbaa";
import MKHHeader from "../components/header/MKHHeader";
import TarbaaHeader from "../components/header/TarbaaHeader";

const Game = () => {
  const { code } = useParams();
  const localGame = localStorage.getItem("game");
  const [game, setGame] = useState(localGame ? JSON.parse(localGame) : null);
  const [theme, setTheme] = useState(null);
  const [ads, setAds] = useState(null);
  const navigate = useNavigate();

  treadmill.register();

  const { data, isError, isLoading, error } = useQuery({
    queryKey: ["game", code],
    queryFn: () => getGameByCode(code),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: activeAds, isLoading: adsLoading } = useQuery({
    queryKey: ["ads"],
    queryFn: () => getAds(),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (!adsLoading) {
      if (activeAds) {
        localStorage.setItem("ads", JSON.stringify(activeAds));
        setAds(activeAds);
      } else {
        const ads = localStorage.getItem("ads");

        setAds(JSON.parse(ads));
      }
    }
  }, [activeAds, adsLoading]);

  useEffect(() => {
    const game = localStorage.getItem("game");
    setGame(JSON.parse(game));
    if (code === "old" && localGame !== null && localGame !== undefined) {
      return;
    } else {
      if (!isLoading) {
        if (data) {
          // Clone the data to avoid mutating original
          const clonedData = { ...data };

          // Set currentTurn to true for a random team
          const randomIndex = Math.floor(
            Math.random() * clonedData.teams.length
          );
          clonedData.teams = clonedData.teams.map((team, index) => ({
            ...team,
            currentTurn: index === randomIndex,
          }));

          // Save to local storage and update state
          localStorage.setItem("game", JSON.stringify(clonedData));
          window.location.reload();
        } else {
          if (error?.response?.data?.message === "invalid code") {
            toast.error("الكود غير صحيح او تم استعماله من قبل");
            navigate("/");
          }

          if (!game || game === null) {
            toast.error("الكود غير صحيح او تم استعماله من قبل");
            navigate("/");
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [data, isError]);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      setTheme(theme);
    }
  }, []);

  useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
    };

    // attach the event listener to
    // the document object
    document.addEventListener("contextmenu", handleContextMenu);

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  if (isLoading && !game) {
    return (
      <div className="h-[100vh] w-full flex justify-center items-center flex-col gap-5">
        <h1 className="text-3xl text-primaryButton">...جاري التحميل</h1>
        <l-treadmill size="120" speed="1.1" color="#516797" />
      </div>
    );
  }

  return (
    <div className="h-full w-full flex flex-col font-sans select-none ">
      {theme === "MKH" ? (
        <>
          <MKHHeader theme={theme} game={game} setGame={setGame} />
          <MKH theme={theme} game={game} setGame={setGame} ads={ads} />
        </>
      ) : theme === "SJ" ? (
        <>
          <SJHeader theme={theme} game={game} setGame={setGame} />
          <SJ theme={theme} game={game} setGame={setGame} ads={ads} />
        </>
      ) : (
        <>
          <TarbaaHeader theme={theme} game={game} setGame={setGame} />
          <Tarbaa theme={theme} game={game} setGame={setGame} ads={ads} />
        </>
      )}
    </div>
  );
};

export default Game;
