import React, { useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import {
  FaClockRotateLeft,
  FaMoneyBillTransfer,
  FaXmark,
} from "react-icons/fa6";
import { HiPhone } from "react-icons/hi2";
import { TbSquareHalf } from "react-icons/tb";
import ReactPlayer from "react-player";
import { useStopwatch, useTimer } from "react-timer-hook";

const Question = ({ game, ads, question, setShowAnswer }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [selectedPowerUp, setSelectedPowerUp] = useState({ name: null });
  const [showFirstLetter, setShowFirstLetter] = useState(false);
  const { seconds, minutes, isRunning, start, pause, reset } = useStopwatch({
    autoStart: true,
  });
  const callTimer = useTimer({
    expiryTimestamp: Date.now() + 30000,
    autoStart: false,
  });

  const modeName = game?.modes?.find((mode) =>
    mode.questions.find(
      (q) => (q?.question?._id || q?.customQuestion?._id) === question?._id
    )
  )?.name;

  const handleFinishPowerUp = () => {
    game.teams
      .find((team) => team._id === selectedPowerUp.team._id)
      .powerUps.find((p) => p.name === selectedPowerUp.name).used = true;
    localStorage.setItem("game", JSON.stringify(game));
    setSelectedPowerUp({ name: null });
  };
  console.log(question);
  return (
    <>
      <div className="w-full h-[89vh] flex flex-col sm:flex-row p-10 py-10 lg:py-20">
        <div className="relative h-3/4 w-full  sm:w-5/6 sm:h-full text-xl lg:text-3xl font-bold text-dark  border-8 border-behindButton rounded-[50px]">
          <div className="h-2/6 lg:h-1/6 w-full flex justify-center items-center p-2 pt-6 px-4 lg:px-10">
            <h1 className="text-center">{question?.question}</h1>
          </div>
          <div className="h-4/6 lg:h-5/6  w-full flex justify-center items-start px-4 pb-4 lg:p-10 ">
            {question?.questionVideo && question?.questionVideo.length > 10 ? (
              <ReactPlayer
                url={question?.questionVideo}
                controls
                width="100%"
                height="100%"
              />
            ) : (
              <button
                onClick={() => {
                  setFullScreen(true);
                }}
                className="h-full"
              >
                <img
                  className="h-full object-contain"
                  src={question?.questionImage}
                  alt={question?.question}
                />
              </button>
            )}
          </div>
          <div className=" w-full flex flex-row justify-between items-center absolute top-[-8%] ">
            <div className="w-1/3 flex justify-center items-center">
              <div className=" hidden sm:block p-1 px-4 lg:p-2 bg-[#343434] text-white rounded-lg  text-sm lg:text-xl">
                {modeName}
              </div>
            </div>
            <div className="w-1/3 flex justify-center items-center">
              <div className="p-1 px-4 lg:p-4 bg-[#343434] text-white rounded-full flex gap-10">
                <button onClick={() => (isRunning ? pause() : start())}>
                  {isRunning ? <FaPause /> : <FaPlay />}
                </button>
                <h1 className="text-center">
                  {minutes < 10 && "0"}
                  {minutes}:{seconds < 10 && "0"}
                  {seconds}
                </h1>
                <button onClick={() => reset()}>
                  <FaClockRotateLeft />
                </button>
              </div>
            </div>
            <div className="w-1/3 flex justify-center items-center">
              <div className="hidden sm:block ">
                <div className="text-right p-1 px-4 lg:p-2 bg-[#343434]  text-white rounded-lg text-sm lg:text-xl flex gap-1">
                  <div>نقطة</div>
                  <div>{question?.points}</div>
                </div>
              </div>
            </div>
          </div>
          {showFirstLetter && (
            <div className="absolute bottom-[-4%] right-10 p-1 px-4 lg:p-2 bg-[#343434] text-white rounded-lg  text-sm lg:text-xl text-right">
              {"أول حرف او رقم: " + question?.answer[0]}
            </div>
          )}
          <div className="absolute bottom-[-8%] left-10">
            <button
              className="bg-mainButton hover:bg-mainButtonShadow text-white font-bold py-3 px-4 lg:px-10 border-b-8 border-mainButtonShadow hover:border-mainButtonShadow rounded-md text-sm lg:text-xl"
              onClick={() => {
                setShowAnswer(true);
              }}
            >
              الإجابة
            </button>
          </div>
        </div>
        <div className="h-1/4 w-full  sm:w-1/4 sm:h-full flex-row sm:flex-col flex justify-center items-center text-white text-4xl pl-4 gap-2 pt-4 sm:pt-0">
          {game?.teams.map((team, index) => (
            <div
              className=" w-1/4 h-full sm:w-full sm:h-1/4 px-[1px] lg:p-2"
              key={index}
            >
              <div
                className={`w-full h-full flex flex-col sm:flex-row justify-center items-center  bg-[#dd9c6e] rounded-lg        ${
                  team.currentTurn === true && "border-4 border-dark"
                }`}
              >
                <div className="w-full h-1/2 sm:w-1/3  sm:h-full flex  justify-center items-center p-1 sm:p-2">
                  <div className=" h-full  aspect-square rounded-full overflow-hidden flex justify-center items-center">
                    <div className=" w-full aspect-square rounded-full overflow-hidden ">
                      <img
                        src={team?.image?.image}
                        alt={team?.name}
                        className="w-full h-full object-cover "
                      />
                    </div>
                  </div>
                </div>
                <div className="h-1/2 w-full sm:w-2/3 sm:h-full text-sm md:text-xl  lg:text-2xl font-bold ">
                  <div className=" h-1/2 flex items-center justify-center overflow-hidden">
                    <h1 className="text-white  text-center">{team?.name}</h1>
                  </div>
                  <div className="w-full h-1/2 flex justify-center items-center p-2 gap-2 lg:gap-4">
                    {team?.powerUps.map((powerUp, index) => (
                      <button
                        disabled={powerUp.used}
                        onClick={() => {
                          setSelectedPowerUp({ name: powerUp?.name, team });
                        }}
                        className="bg-mainButtonShadow hover:bg-mainButton rounded-lg p-2 text-lg lg:text-2xl disabled:bg-[#94674690] disabled:text-[#ffffff90]"
                        key={index}
                      >
                        {powerUp?.name === "one" ? (
                          <FaMoneyBillTransfer />
                        ) : powerUp?.name === "two" ? (
                          <HiPhone />
                        ) : (
                          <TbSquareHalf />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {fullScreen && (
        <button
          onClick={() => {
            setFullScreen(false);
          }}
          className="absolute h-[100vh] w-[100vw] bg-[#00000090]"
        >
          <img
            src={question?.questionImage}
            alt={question?.question}
            className="h-full w-full object-contain"
          />
        </button>
      )}
      {selectedPowerUp.name && (
        <>
          <div className="z-[9999999] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none text-right">
            <div className="relative w-auto my-6 mx-auto max-w-md md:max-w-3xl ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="  flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t gap-20">
                  <div>
                    <button
                      className=" p-1 ml-auto bg-transparent border-0 text-black opacity-15 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setSelectedPowerUp({ name: null })}
                    >
                      <FaXmark />
                    </button>
                  </div>
                  <div className=" flex flex-col gap-2">
                    <h3 className="text-3xl font-semibold text-right">
                      {selectedPowerUp.name === "one"
                        ? "ما عحبني بغيّره"
                        : selectedPowerUp.name === "two"
                        ? "اتصال بصديق"
                        : "أول حرف"}
                    </h3>
                  </div>
                </div>
                {/*body*/}
                <div className="relative p-6 flex flex-col justify-center items-center gap-5">
                  <div className="flex flex-row justify-end items-center gap-2 w-full">
                    <h1>{selectedPowerUp.team.name}</h1>
                    <img
                      className="h-10 aspect-square rounded-full"
                      src={selectedPowerUp?.team?.image?.image}
                      alt={selectedPowerUp?.team?.name}
                    />
                  </div>
                  <h1 className="text-center font-bold">
                    {selectedPowerUp.name === "one"
                      ? "تقدر تغيّر السؤال حق سؤال عشوائي ثاني بس ها! مو شرط نفس نقاط هالسؤال"
                      : selectedPowerUp.name === "two"
                      ? "أول ما يرد الي داقين عليه تبلشون التايمر"
                      : "لما تستعملها بيطلع اول حرف او رقم من الجواب"}
                  </h1>
                  {selectedPowerUp.name === "two" && (
                    <>
                      <button
                        className="border-4 h-36 aspect-square rounded-full overflow-hidden  font-semibold text-2xl"
                        onClick={() => callTimer.resume()}
                      >
                        <h1
                          className={`${
                            callTimer.totalSeconds > 15 &&
                            callTimer.totalSeconds <= 30 &&
                            callTimer.isRunning
                              ? "text-green-400"
                              : callTimer.totalSeconds > 0 &&
                                callTimer.totalSeconds <= 15
                              ? "text-yellow-400"
                              : callTimer.totalSeconds === 0
                              ? "text-red-400"
                              : "text-dark"
                          }`}
                        >
                          {callTimer.totalSeconds > 0 &&
                          callTimer.totalSeconds <= 30 &&
                          callTimer.isRunning ? (
                            (callTimer < 10 && "0") + callTimer.totalSeconds
                          ) : callTimer.totalSeconds === 0 ? (
                            "انتهى الوقت"
                          ) : (
                            <div className=" flex justify-center items-center flex-col">
                              <h1>بلش التايمر</h1>
                              <HiPhone />
                            </div>
                          )}
                        </h1>
                      </button>
                    </>
                  )}
                  <button
                    onClick={
                      selectedPowerUp.name === "one"
                        ? () => {
                            const randomQuestion =
                              game.spareQuestions[
                                Math.floor(
                                  Math.random() * game.spareQuestions.length
                                )
                              ];
                            game.spareQuestions = game.spareQuestions.filter(
                              (q) => q._id !== randomQuestion._id
                            );
                            //replace the current question in the mode with the new question
                            game.modes
                              .find((mode) =>
                                mode.questions.find(
                                  (q) =>
                                    (q?.question?._id ||
                                      q?.customQuestion?._id) === question?._id
                                )
                              )
                              .questions.find(
                                (q) =>
                                  (q?.question?._id ||
                                    q?.customQuestion?._id) === question?._id
                              ).question = randomQuestion;
                            localStorage.setItem("game", JSON.stringify(game));
                            setSelectedPowerUp({ name: null });
                            handleFinishPowerUp();
                            window.location.reload();
                          }
                        : selectedPowerUp.name === "two"
                        ? () => {
                            callTimer.restart(new Date(Date.now() + 30000));
                            callTimer.pause();
                            handleFinishPowerUp();
                          }
                        : () => {
                            setShowFirstLetter(true);
                            handleFinishPowerUp();
                          }
                    }
                    className="bg-secondaryButton text-white p-4 rounded-xl"
                  >
                    {selectedPowerUp.name === "one"
                      ? "غيّر السؤال"
                      : selectedPowerUp.name === "two"
                      ? "خلصت المكالمة"
                      : "إستعمال"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default Question;
