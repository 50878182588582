import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

const MKH = ({ game, ads }) => {
  return (
    <div className="w-full h-[91vh] ">
      <div className="w-full h-full p-2 md:p-6 flex flex-col sm:flex-row">
        <div className="h-5/6 sm:h-full w-full sm:w-5/6 flex flex-row flex-wrap text-base sm:text-lg lg:text-2xl font-bold justify-end ">
          {game?.modes?.map((mode) => (
            <div className=" w-2/6 sm:h-full sm:w-1/6 pr-1">
              <div
                className={`relative h-full w-full rounded-2xl  bg-[#bfe4f3] p-1 ${
                  mode?.custom && "border-4 border-mainText"
                }`}
              >
                <div className="relative h-1/6 bg-[#eaf6fb] rounded-xl overflow-hidden flex justify-center items-center">
                  <div className=" w-[70%] sm:min-w-32 sm:w-[96%] aspect-square border-4 border-gray-600 rounded-full overflow-hidden opacity-35 absolute top-0 z-0">
                    <img
                      src={mode?.image}
                      alt={mode?.name}
                      className="w-full h-full object-cover "
                    />
                  </div>
                  <div className=" opacity-100 z-10 px:1 p-1  sm:px-3 sm:p-2 bg-[#94d2ea] rounded-full">
                    <h1 className="text-sm sm:text-sm lg:text-lg text-center">
                      {mode?.name}
                    </h1>
                  </div>
                </div>
                <div className="h-5/6 ">
                  {mode?.questions?.map((question) => (
                    <div className="h-1/6 pt-1 px-1">
                      <button className="h-full w-full bg-white rounded-xl">
                        {question?.question?.points ||
                          question?.customQuestion?.points}
                      </button>
                    </div>
                  ))}
                </div>
                {/* {mode?.custom && (
                  <div className="hidden  lg:absolute w-full lg:flex justify-center items-center top-[-3%] z-50">
                    <div className=" text-sm  rounded-xl p-2 bg-mainText ">
                      <h1> فئة خاصة</h1>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          ))}
        </div>
        <div className="h-1/6 sm:h-full w-full sm:w-1/6 py-2 sm:py-0">
          <div className="h-full w-full rounded-2xl  bg-[#29a6d6] flex sm:flex-col">
            <div
              className={` p-1 h-full sm:w-full ${
                game?.teams?.length > 2 ? "sm:h-1/5 w-1/5" : "sm:h-full w-full"
              }`}
            >
              <div className="bg-[#eaf6fb] h-full rounded-l-xl sm:rounded-bl-none sm:rounded-t-xl overflow-hidden">
                <div className="h-1/2 flex justify-center items-center">
                  <h1 className="text-sm lg:text-lg font-semibold">
                    {game?.teams[0]?.name}
                  </h1>
                </div>
                <div className="h-1/2 flex justify-between items-center bg-white border-t-2">
                  <button className="h-full w-3/12 flex justify-center items-center bg-[#bfe4f3]">
                    <FaMinus color="black" size={12} />
                  </button>
                  <h1 className="text-sm lg:text-lg font-bold">
                    {game?.teams[0]?.points}
                  </h1>
                  <button className="h-full w-3/12 flex justify-center items-center bg-[#bfe4f3]">
                    <FaPlus color="black" size={12} />
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`w-1/5 p-1 sm:p-0 sm:pb-1 sm:px-1 h-full sm:h-1/5 sm:w-full  ${
                game?.teams?.length > 2 ? "" : "hidden"
              }`}
            >
              <div className="bg-[#eaf6fb] h-full  overflow-hidden">
                <div className="h-1/2 flex justify-center items-center">
                  <h1 className="text-sm lg:text-lg font-semibold">
                    {game?.teams[2]?.name}
                  </h1>
                </div>
                <div className="h-1/2 flex justify-between items-center bg-white border-t-2">
                  <button className="h-full w-3/12 flex justify-center items-center bg-[#bfe4f3]">
                    <FaMinus color="black" size={12} />
                  </button>
                  <h1 className="text-sm lg:text-lg font-bold">
                    {game?.teams[2]?.points}
                  </h1>
                  <button className="h-full w-3/12 flex justify-center items-center bg-[#bfe4f3]">
                    <FaPlus color="black" size={12} />
                  </button>
                </div>
              </div>
            </div>

            <div className="w-1/5 h-full sm:h-1/5  sm:w-full flex justify-center items-center overflow-hidden">
              <img
                className="h-full aspect-square object-cover"
                src={ads[0]?.image}
                alt={ads[0]?.name}
              />
            </div>

            <div
              className={`p-1 h-full sm:w-full ${
                game?.teams?.length > 2 ? "sm:h-1/5 w-1/5" : "sm:h-full w-full"
              }`}
            >
              <div className="bg-[#eaf6fb] h-full overflow-hidden">
                <div className="h-1/2 flex justify-center items-center">
                  <h1 className="text-sm lg:text-lg font-semibold">
                    {game?.teams[1]?.name}
                  </h1>
                </div>
                <div className="h-1/2 flex justify-between items-center bg-white border-t-2">
                  <button className="h-full w-3/12 flex justify-center items-center bg-[#bfe4f3]">
                    <FaMinus color="black" size={12} />
                  </button>
                  <h1 className="text-sm lg:text-lg font-bold">
                    {game?.teams[1]?.points}
                  </h1>
                  <button className="h-full w-3/12 flex justify-center items-center bg-[#bfe4f3]">
                    <FaPlus color="black" size={12} />
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`w-1/5 p-1 sm:p-0 sm:pb-1 sm:px-1 h-full sm:h-1/5 sm:w-full ${
                game?.teams?.length > 2 ? "" : "hidden"
              }`}
            >
              <div className="bg-[#eaf6fb] h-full rounded-r-xl sm:rounded-tr-none sm:rounded-b-xl overflow-hidden">
                <div className="h-1/2 flex justify-center items-center">
                  <h1 className="text-sm lg:text-lg font-semibold">
                    {game?.teams[3]?.name}
                  </h1>
                </div>
                <div className="h-1/2 flex justify-between items-center bg-white border-t-2">
                  <button className="h-full w-3/12 flex justify-center items-center bg-[#bfe4f3]">
                    <FaMinus color="black" size={12} />
                  </button>
                  <h1 className="text-sm lg:text-lg font-bold">
                    {game?.teams[3]?.points}
                  </h1>
                  <button className="h-full w-3/12 flex justify-center items-center bg-[#bfe4f3]">
                    <FaPlus color="black" size={12} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//#29a6d6
//bfe4f3
//#eaf6fb

export default MKH;
