import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getModesToDisplay } from "./api/mode";
import "./App.css";
import { toast } from "react-toastify";
import logo from "./assets/images/IMG_4230.jpeg";
import playstore from "./assets/images/playstore.png";
import appstore from "./assets/images/appstore.png";
import instagram from "./assets/images/instagram.png";

function App() {
  const [code, setCode] = useState("");
  const [oldGame, setOldGame] = useState(false);

  const navigation = useNavigate();

  const { data } = useQuery({
    queryKey: ["modes"],
    queryFn: () => getModesToDisplay(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    const game = localStorage.getItem("game");
    if (game) {
      setOldGame(true);
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col gap-8 font-sans">
      <div className="relative min-h-[80vh]  items-center flex flex-col gap-4 bg-background rounded-br-3xl rounded-bl-3xl md:rounded-br-[40px] md:rounded-bl-[40px] pb-32">
        {/* <h1 className=" text-secondaryButton text-8xl">تربع</h1> */}
        <div className="flex justify-center items-center">
          <img src={logo} alt="logo" className="w-72 md:w-96 lg:w-1/4" />
        </div>
        <div className=" justify-center items-center flex flex-col gap-4">
          <input
            className=" rounded-lg border-2 border-behindButton text-dark p-2 bg-white text-center px-12 py-4 text-2xl"
            maxLength={6}
            value={code}
            onChange={(e) => setCode(e.target.value.toUpperCase())}
            placeholder="كود اللعبة"
          />
          <button
            className="bg-mainButton hover:bg-mainButtonShadow text-white font-bold py-3 px-10 border-b-4 border-mainButtonShadow hover:border-mainButtonShadow rounded "
            onClick={() => {
              if (code.length < 6) return toast.error("الكود ليس صحيح");
              navigation(`/${code}`);
            }}
          >
            بلّش
          </button>
          {oldGame && (
            <button
              className="bg-secondaryButton hover:bg-secondaryButtonShadow text-white font-bold py-3 px-10 border-b-4 border-secondaryButtonShadow hover:border-secondaryButtonShadow rounded "
              onClick={() => {
                navigation(`/old`);
              }}
            >
              كمل اللعبة الي طافت
            </button>
          )}
        </div>
        <div className="absolute bottom-10 flex gap-6">
          <Link
            to={
              "https://play.google.com/store/apps/details?id=com.azmenezi.tarbaa"
            }
            className="w-10 aspect-square overflow-hidden rounded-lg"
          >
            <img src={playstore} alt="playstore" className="w-full" />
          </Link>
          <Link
            to={"https://www.instagram.com/tarbaakw/"}
            className="w-10 aspect-square overflow-hidden rounded-lg"
          >
            <img src={instagram} alt="playstore" className="w-full" />
          </Link>
          <Link
            to={
              "https://apps.apple.com/app/tarbaa-%D8%AA%D8%B1-%D8%A8-%D8%B9/id6499569262"
            }
            className="w-10 aspect-square overflow-hidden rounded-lg"
          >
            <img src={appstore} alt="playstore" className="w-full" />
          </Link>
        </div>
      </div>
      <div className=" flex flex-col gap-6">
        <h2 className=" text-dark text-4xl text-center font-bold">فئاتنا</h2>
        <div className=" flex flex-wrap items-center justify-center gap-3 p-2">
          {data &&
            data.map((mode) => (
              <div
                className="  aspect-square w-44 md:w-44 lg:w-44 bg-black rounded-xl overflow-hidden relative flex items-center justify-center border-behindButton border-4"
                key={mode.id}
              >
                <img
                  className="h-full w-full object-cover"
                  src={mode.image}
                  alt={mode.name}
                />
                <div className="absolute w-full h-full text-white bg-black bg-opacity-60 flex items-center justify-center  text-2xl font-bold">
                  <h1 className=" text-center">{mode.name}</h1>
                </div>
              </div>
            ))}
        </div>
      </div>
      <footer className="bg-background text-center text-white p-4">
        <p>جميع الحقوق محفوظة</p>
        <p>© 2023 تربع</p>
      </footer>
    </div>
  );
}

export default App;
