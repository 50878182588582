import React from "react";

const ModesSection = ({ game }) => {
  return (
    <div className="h-[60vh] md:h-[70vh] flex flex-row flex-wrap p-1 md:p-4">
      {game?.modes.map((mode, index) => {
        // Sort the questions based on points ascending
        const sortedQuestions = mode.questions.slice().sort((a, b) => {
          const pointsA = a.question?.points || a.customQuestion?.points;
          const pointsB = b.question?.points || b.customQuestion?.points;
          return pointsA - pointsB;
        });

        // Partition into left and right questions
        const leftQuestions = sortedQuestions.filter((q, idx) => idx % 2 === 0);
        const rightQuestions = sortedQuestions.filter(
          (q, idx) => idx % 2 !== 0
        );

        return (
          <div
            key={index}
            className="flex w-1/2 h-1/3  sm:w-1/3 sm:h-1/2  p-1  lg:p-2 "
          >
            <div className="flex w-full h-full">
              {/* Left Column */}
              <div className="w-1/3 flex flex-col gap-1">
                {leftQuestions.map((q, idx) => (
                  <button
                    key={idx}
                    className="flex-1 bg-[#d1d3d4] rounded-bl-full rounded-tl-full overflow-hidden"
                  >
                    <div className="w-full h-full text-secondaryButtonShadow text-2xl md:text-3xl  flex items-center justify-center font-sans font-semibold">
                      <h1>
                        {q?.question?.points || q?.customQuestion?.points}
                      </h1>
                    </div>
                  </button>
                ))}
              </div>

              {/* Middle Column - Image */}
              <div className="relative w-1/3 flex items-center justify-center">
                <div
                  className={`w-full  h-full ${
                    mode?.custom && "border-4 border-mainText"
                  }`}
                >
                  <div className="h-full">
                    <img
                      src={mode?.image}
                      alt={mode?.name}
                      className="h-[78%] w-full object-cover"
                    />
                    <div className="h-[22%] flex justify-center items-center bg-[#3B62CE] border-t-2 border-black">
                      <h1 className="text-white text-xs sm:text-[0.5rem] md:text-lg text-center font-sans font-bold">
                        {mode?.name}
                      </h1>
                    </div>
                  </div>
                </div>
                {/* {mode?.custom && (
                  <div className=" hidden lg:block lg:absolute  rounded-xl p-2 bg-mainText top-[-5%] z-50">
                    <h1> فئة خاصة</h1>
                  </div>
                )} */}
              </div>

              {/* Right Column */}
              <div className="w-1/3 flex flex-col gap-1">
                {rightQuestions.map((q, idx) => (
                  <button
                    key={idx}
                    className="flex-1 bg-[#d1d3d4] rounded-br-full rounded-tr-full overflow-hidden"
                  >
                    <div className="w-full h-full text-secondaryButtonShadow  text-2xl md:text-3xl flex items-center justify-center font-sans font-semibold">
                      <h1>
                        {q?.question?.points || q?.customQuestion?.points}
                      </h1>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ModesSection;
