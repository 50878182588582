import React, { useEffect, useState } from "react";
import MKHHeader from "../components/header/MKHHeader";
import SJHeader from "../components/header/SJHeader";
import TarbaaHeader from "../components/header/TarbaaHeader";
import TarbaaQuestion from "../components/tarbaa/Question";
import TarbaaAnswer from "../components/tarbaa/Answer";
import MKHQuestion from "../components/mkh/Question";
import SJQuestion from "../components/sj/Question";
import { useParams } from "react-router-dom";
import WhoAnswered from "../components/tarbaa/WhoAnswered";

const Question = () => {
  const { id } = useParams();
  const [theme, setTheme] = useState("Tarbaa");
  const [game, setGame] = useState(null);
  const [ads, setAds] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showWhoAnswered, setShowWhoAnswered] = useState(false);

  const question = game?.modes
    ?.find((mode) => mode.questions.find((q) => q._id === id))
    .questions.find((q) => q._id === id).question
    ? {
        ...game?.modes
          ?.find((mode) => mode.questions.find((q) => q._id === id))
          .questions.find((q) => q._id === id).question,
        custom: false,
      }
    : {
        ...game?.modes
          ?.find((mode) => mode.questions.find((q) => q._id === id))
          .questions.find((q) => q._id === id).customQuestion,
        custom: true,
      };

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    const game = localStorage.getItem("game");
    const ads = localStorage.getItem("ads");
    if (theme) {
      setTheme(theme);
    }
    if (game) {
      setGame(JSON.parse(game));
    }
    if (ads) {
      setAds(JSON.parse(ads));
    }
  }, []);

  useEffect(() => {
    if (game !== null) {
      localStorage.setItem("game", JSON.stringify(game));
    }
  }, [game]);

  return (
    <div className="h-full w-full flex flex-col font-sans select-none ">
      {theme === "MKH" ? (
        <>
          <MKHHeader theme={theme} game={game} setGame={setGame} ads={ads} />
          <MKHQuestion
            theme={theme}
            game={game}
            setGame={setGame}
            ads={ads}
            question={question}
          />
        </>
      ) : theme === "SJ" ? (
        <>
          <SJHeader theme={theme} game={game} setGame={setGame} ads={ads} />
          <SJQuestion
            theme={theme}
            game={game}
            setGame={setGame}
            ads={ads}
            question={question}
          />
        </>
      ) : (
        <>
          <TarbaaHeader theme={theme} game={game} setGame={setGame} ads={ads} />
          {showAnswer ? (
            <>
              {showWhoAnswered ? (
                <WhoAnswered
                  theme={theme}
                  game={game}
                  setGame={setGame}
                  ads={ads}
                  question={question}
                  setShowAnswer={setShowAnswer}
                  setShowWhoAnswered={setShowWhoAnswered}
                />
              ) : (
                <TarbaaAnswer
                  theme={theme}
                  game={game}
                  setGame={setGame}
                  ads={ads}
                  question={question}
                  setShowAnswer={setShowAnswer}
                  setShowWhoAnswered={setShowWhoAnswered}
                />
              )}
            </>
          ) : (
            <TarbaaQuestion
              theme={theme}
              game={game}
              setGame={setGame}
              ads={ads}
              question={question}
              setShowAnswer={setShowAnswer}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Question;
