import React, { useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaPause, FaPlay } from "react-icons/fa";
import { FaClockRotateLeft, FaMoneyBillTransfer } from "react-icons/fa6";
import { useStopwatch } from "react-timer-hook";

const Answer = ({ game, ads, question, setShowAnswer, setShowWhoAnswered }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const { seconds, minutes, isRunning, start, pause, reset } = useStopwatch({
    autoStart: true,
  });

  const modeName = game?.modes?.find((mode) =>
    mode.questions.find(
      (q) => (q?.question?._id || q?.customQuestion?._id) === question?._id
    )
  )?.name;
  console.log(question);
  return (
    <>
      <div className="w-full h-[89vh] flex flex-col sm:flex-row p-10 py-10 lg:py-20">
        <div className="relative h-3/4 w-full  sm:w-5/6 sm:h-full text-xl lg:text-3xl font-bold text-dark  border-8 border-behindButton rounded-[50px]">
          <div className="h-2/6 lg:h-1/6 w-full flex justify-center items-center p-2 pt-6 px-4 lg:px-10">
            <h1 className="text-center">{question?.answer}</h1>
          </div>
          <div className="h-4/6 lg:h-5/6  w-full flex justify-center items-start px-4 pb-4 lg:p-10 ">
            <button
              onClick={() => {
                setFullScreen(true);
              }}
              className="h-full"
            >
              <img
                className="h-full object-contain"
                src={question?.answerImage || question?.questionImage}
                alt={question?.answer}
              />
            </button>
          </div>
          <div className=" w-full flex flex-row justify-between items-center absolute top-[-8%] ">
            <div className="w-1/3 flex justify-center items-center">
              <div className=" hidden sm:block p-1 px-4 lg:p-2 bg-[#343434] text-white rounded-lg  text-sm lg:text-xl">
                {modeName}
              </div>
            </div>
            <div className="w-1/3 flex justify-center items-center">
              <div className="p-1 px-4 lg:p-4 bg-[#343434] text-white rounded-full flex gap-10">
                <button onClick={() => (isRunning ? pause() : start())}>
                  {isRunning ? <FaPause /> : <FaPlay />}
                </button>
                <h1 className="text-center">
                  {minutes < 10 && "0"}
                  {minutes}:{seconds < 10 && "0"}
                  {seconds}
                </h1>
                <button onClick={() => reset()}>
                  <FaClockRotateLeft />
                </button>
              </div>
            </div>
            <div className="w-1/3 flex justify-center items-center">
              <div className="hidden sm:block ">
                <div className="text-right p-1 px-4 lg:p-2 bg-[#343434]  text-white rounded-lg text-sm lg:text-xl flex gap-1">
                  <div>نقطة</div>
                  <div>{question?.points}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-[-8%] left-10">
            <button
              className="bg-secondaryButton hover:bg-secondaryButtonShadow text-white font-bold py-3 px-4 lg:px-10 border-b-8 border-secondaryButtonShadow hover:border-secondaryButtonShadow rounded-md text-sm lg:text-xl"
              onClick={() => {
                setShowWhoAnswered(true);
              }}
            >
              منو جاوب
            </button>
          </div>
          <div className="absolute bottom-[-8%] right-10">
            <button
              className="bg-mainButton hover:bg-mainButtonShadow text-white font-bold py-3 px-4 lg:px-10 border-b-8 border-mainButtonShadow hover:border-mainButtonShadow rounded-md text-sm lg:text-xl"
              onClick={() => {
                setShowAnswer(false);
              }}
            >
              السؤال
            </button>
          </div>
        </div>
        <div className="h-1/4 w-full  sm:w-1/4 sm:h-full flex-row sm:flex-col flex justify-center items-center text-white text-4xl pl-4 gap-2 pt-4 sm:pt-0">
          {game?.teams.map((team, index) => (
            <div
              className=" w-1/4 h-full sm:w-full sm:h-1/4 px-[1px] lg:p-2"
              key={index}
            >
              <div
                className={`w-full h-full flex flex-col sm:flex-row justify-center items-center  bg-[#dd9c6e] rounded-lg        ${
                  team.currentTurn === true && "border-4 border-dark"
                }`}
              >
                <div className="w-full h-1/2 sm:w-1/3  sm:h-full flex  justify-center items-center p-1 sm:p-2">
                  <div className=" h-full  aspect-square rounded-full overflow-hidden flex justify-center items-center">
                    <div className=" w-full aspect-square rounded-full overflow-hidden ">
                      <img
                        src={team?.image?.image}
                        alt={team?.name}
                        className="w-full h-full object-cover "
                      />
                    </div>
                  </div>
                </div>
                <div className="h-1/2 w-full sm:w-2/3 sm:h-full text-sm md:text-xl  lg:text-2xl font-bold ">
                  <div className=" h-1/2 flex items-center justify-center overflow-hidden">
                    <h1 className="text-white  text-center">{team?.name}</h1>
                  </div>
                  <div className="w-full h-1/2 flex justify-center items-center p-2 gap-2 lg:gap-4">
                    {team?.powerUps.map((powerUp, index) => (
                      <button
                        disabled
                        className="bg-mainButtonShadow hover:bg-mainButton rounded-lg p-2 text-lg lg:text-2xl disabled:bg-[#94674690] disabled:text-[#ffffff90]"
                        key={index}
                      >
                        {powerUp?.name === "one" ? (
                          <BsFillTelephoneFill />
                        ) : powerUp?.name === "two" ? (
                          <FaMoneyBillTransfer />
                        ) : (
                          <FaMoneyBillTransfer />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {fullScreen && (
        <button
          onClick={() => {
            setFullScreen(false);
          }}
          className="absolute h-[100vh] w-[100vw] bg-[#00000090]"
        >
          <img
            src={question?.answerImage}
            alt={question?.answer}
            className="h-full w-full object-contain"
          />
        </button>
      )}
    </>
  );
};

export default Answer;
